var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"mb-5",attrs:{"no-body":""}},[_c('b-row',{attrs:{"no-gutters":"","align-v":"stretch"}},[_c('b-col',{staticClass:"d-none d-md-block",staticStyle:{"background-color":"#FAFAFA","max-height":"450px"},attrs:{"cols":"12","lg":"5","xxl":"5"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.imageSrc,"alt":""}})]),_c('b-col',{staticClass:"p-4 default-bg",attrs:{"cols":"12","lg":"7","xxl":"7","align-self":"stretch"}},[_c('b-row',{staticStyle:{"min-height":"100%"},attrs:{"align-v":"stretch"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-0 text-default med-font",staticStyle:{"font-size":"22px","line-height":"32px"}},[_vm._v(" "+_vm._s(_vm.item.medicinename)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.item.dosage)+" | "+_vm._s(_vm.item.unit)+" | "+_vm._s(_vm.duration)+" ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-block d-md-none pt-6"}),(_vm.item.symptom.length > 0)?_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"d-flex text-default justify-content-between align-items-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$emit(
                        'show-modal',
                        'What this is good for',
                        _vm.formatGoodFor(_vm.item.symptom)
                      )}}},[_vm._v(" What this is good for "),_c('span',{staticClass:"next-arrow-cyan"})])],1)],1)],1):_vm._e(),(_vm.item.whattoexpect.text)?_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"d-flex text-default justify-content-between align-items-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit(
                      'show-modal',
                      'What to expect',
                      _vm.item.whattoexpect.html
                    )}}},[_vm._v(" What to expect "),_c('span',{staticClass:"next-arrow-cyan"})])],1)],1)],1):_vm._e(),(_vm.item.sideeffects.text)?_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"d-flex text-default justify-content-between align-items-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit(
                      'show-modal',
                      'What are the side effects',
                      _vm.item.sideeffects.html
                    )}}},[_vm._v(" What are the side effects "),_c('span',{staticClass:"next-arrow-cyan"})])],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-none d-md-block text-right mt-4"},[_c('b-button',{staticStyle:{"min-width":"220px"},attrs:{"pill":"","size":"lg","variant":"info","to":'/subscription/' + _vm.item.id}},[_vm._v(" View subscription ")])],1),_c('div',{staticClass:"d-block d-md-none text-right mt-4"},[_c('b-button',{attrs:{"block":"","pill":"","variant":"info","to":'/subscription/' + _vm.item.id}},[_vm._v(" View subscription ")])],1),_c('div',{staticClass:"d-block d-md-none pb-3"})],1)],1)],1),_c('b-col',{staticClass:"d-block d-md-none",staticStyle:{"background-color":"#FAFAFA","max-height":"450px"},attrs:{"cols":"12","lg":"5","xxl":"5"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.imageSrc,"alt":""}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }